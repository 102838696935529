import './styled.css';
import { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../components/header';
import { useMask } from '../../hooks/useMask';
import { getTickets } from '../../utils/service/product';
import { Information } from '../../components/information';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useVar } from '../../hooks/useVar';
import { useTranslation } from 'react-i18next';

export const Cart = () => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const ticketsname = t('tickets', { returnObjects: true });
  const { setSelectedProduct, productDefault, login, fillLogin } =
    useContext(TransactionContext);
  const { brlMask } = useMask();
  const { eventId } = useVar();
  const [tickets, setTickets] = useState([]);
  const [product, setProduct] = useState(productDefault);

  const setCount = (value, index) =>
    setTickets((prevState) => {
      const newState = [...prevState];
      newState[index] = { ...newState[index], count: value };
      return newState;
    });

  useEffect(() => {
    if (login.language === null) {
      navigate('/');
    } else {
      fillLogin('domain', t('email_suffix'));
    }

    getTickets(eventId).then((data) => {
      const currentTickets = data.map((newTicket) => ({
        ...newTicket,
        count: 0,
      }));
      setTickets(currentTickets);
    });
  }, [eventId, login.language]);

  return (
    <section className='section-cart'>
      <Header />
      <Card className='card-cart'>
        <CardBody>
          <Row>
            <Col
              className='home-left-side'
              md={6}
            >
              <Information />
            </Col>
            <Col md={6}>
              <table>
                <tbody>
                  {tickets.map((ticket, index) => (
                    <tr key={index}>
                      <td>
                        {ticketsname.find((x) => x.order === ticket.order).name}
                      </td>
                      <td>{brlMask(ticket.price)}</td>
                      <td>
                        <div className='ticket-button-wrapper'>
                          <button
                            className={`count-purchase`}
                            onClick={(e) => {
                              if (product.id === ticket.id) {
                                setCount(0, index);
                                setProduct(productDefault);
                              }
                            }}
                          >
                            -
                          </button>
                          <span>{ticket.count}</span>
                          <button
                            className={`count-purchase ${
                              product.id !== '' ? 'count-purchase-disabled' : ''
                            }`}
                            onClick={(e) => {
                              setCount(1, index);
                              setProduct(ticket);
                            }}
                            disabled={product.id !== ''}
                          >
                            +
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Row className='row-btn-submit'>
                {tickets.length > 0 && (
                  <button
                    className='btn-submit'
                    onClick={(e) => {
                      if (product.id === '') {
                        e.preventDefault();
                      } else {
                        setSelectedProduct(
                          tickets.find((ticket) => ticket.count > 0),
                        );
                        navigate('/pagamento');
                      }
                    }}
                  >
                    {t('cart.buy')}
                  </button>
                )}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </section>
  );
};
