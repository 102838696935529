import { Row, Col } from 'reactstrap';
import { useContext } from 'react';
import { useMask } from '../../hooks/useMask';
import { usePaymentOption } from '../../hooks/usePaymentOption';
import { useTranslation } from 'react-i18next';
import { TransactionContext } from '../../contexts/TransactionContext';

export const Customer = ({
  customer,
  fillCustomer,
  free,
  paymentType,
  identification,
}) => {
  const { login } = useContext(TransactionContext);
  const {
    alphabetMask,
    emailMask,
    documentMask,
    mobileMask,
    onDelete,
    numberMask,
  } = useMask();
  const [t] = useTranslation();

  return (
    <>
      <h3
        className='title2'
        style={{ marginTop: 20 }}
      >
        Dados {free ? 'de envio' : 'de cobrança'}
      </h3>
      <Row className='mt-2'>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-name`}
          >
            {t('payment.pix.fullname')}*
          </label>
          <input
            id={`customer-name`}
            value={customer.name}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = alphabetMask(value);
              fillCustomer('name', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-email`}
          >
            {t('payment.pix.email')}*
          </label>
          <input
            id={`customer-email`}
            value={customer.email}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = emailMask(value);
              fillCustomer('email', newValue);
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-document`}
          >
            {identification}*
          </label>
          <input
            id={`customer-document`}
            value={customer.document}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              fillCustomer('document', value);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-mobile`}
          >
            {t('payment.customer.mobile')}*
          </label>
          <input
            id={`customer-mobile`}
            value={customer.mobile}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = numberMask(value);
              fillCustomer('mobile', newValue);
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-address`}
          >
            {t('payment.customer.address')}*
          </label>
          <input
            id={`customer-address`}
            value={customer.address}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              fillCustomer('address', value);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-city`}
          >
            {t('payment.customer.city')}*
          </label>
          <input
            id={`customer-city`}
            value={customer.city}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              fillCustomer('city', value);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`customer-state`}
          >
            {t('payment.customer.state')}*
          </label>
          <input
            id={`customer-state`}
            value={customer.state}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              fillCustomer('state', value);
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
    </>
  );
};
