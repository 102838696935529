import './styled.css';
import { useState, useEffect, useContext } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import Header from '../../components/header';
import { Modal } from '../../components/modal';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { useTranslation } from 'react-i18next';
import { useMask } from '../../hooks/useMask';
import { useLanguage } from '../../hooks/useLanguage';
import { useError } from '../../hooks/useError';
import { isApproved } from '../../utils/service/payment';

export const Home = () => {
  const navigate = useNavigate();

  const { login, fillLogin } = useContext(TransactionContext);
  const { loginError, goToFieldWithError } = useError();

  const { emailMask } = useMask();
  const { PORTUGUESE, SPANISH } = useLanguage();

  const [t, i18n] = useTranslation();
  const countries = t('home.countries.options', { returnObjects: true });

  const [htmlId, setHtmlId] = useState('');
  const handleChangeLanguage = (language) => {
    fillLogin('language', language);
    i18n.changeLanguage(language);
  };

  const [message, setMessage] = useState('');
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
    goToFieldWithError(htmlId);
  };

  useEffect(() => {
    document.documentElement.setAttribute('lang', t('language'));
    document.title = t('title');
    fillLogin('country', login.language === PORTUGUESE ? 'BRA' : '');
  }, [login.language]);

  const validate = (model, error) => {
    let errorFor = false;
    for (let property in error) {
      const field = error[property];
      for (let current in field) {
        if (field[current.toString()].func(model)) {
          const id = `home-${property}`;
          setHtmlId(id);
          const msg = `home.errors.${property}_empty`;
          setMessage(t(msg));
          setOpen(true);
          errorFor = true;
          break;
        }
      }
      if (errorFor) {
        break;
      }
    }
    return !errorFor;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const success = validate(login, loginError, 'login');
    if (!success) {
      return;
    }
    const data = await isApproved(login.email);
    if (data.success) {
      navigate('/carrinho');
    } else {
      setHtmlId('home-email');
      setMessage(t(`home.errors.${data.message}`));
      setOpen(true);
    }
  };

  return (
    <section className='section-cart'>
      <Modal
        open={open}
        closeModal={closeModal}
      >
        {message}
      </Modal>
      <Header />
      <Card className='card-cart'>
        {login.language === null && (
          <CardBody className='justify-content-center'>
            <Row className='mt-2'>
              <Col className='d-flex flex-row gap-2 justify-content-center'>
                <button
                  className='btn-submit'
                  onClick={() => handleChangeLanguage(PORTUGUESE)}
                >
                  Português
                </button>
                <button
                  className='btn-submit'
                  onClick={() => handleChangeLanguage(SPANISH)}
                >
                  Español
                </button>
              </Col>
            </Row>
          </CardBody>
        )}
        {login.language && (
          <form autoComplete='off'>
            <CardBody className='justify-content-center'>
              <Row className='mt-2 justify-content-center'>
                <Col md={6}>
                  <label className='form-label'>
                    {t('home.countries.label')}
                  </label>
                  <select
                    id={`home-country`}
                    className='form-control select-style'
                    value={login.country}
                    onChange={(e) => {
                      fillLogin('country', e.target.value);
                    }}
                  >
                    {login.language === SPANISH && (
                      <option
                        key={-1}
                        value={''}
                      ></option>
                    )}

                    {countries.map((country, index) => (
                      <option
                        key={index}
                        value={country.code}
                      >
                        {country.name}
                      </option>
                    ))}
                  </select>
                </Col>
              </Row>
              <Row className='mt-4 justify-content-center'>
                <Col md={6}>
                  <label className='form-label'>{t('home.email')}</label>
                  <input
                    id={`home-email`}
                    type='text'
                    className='form-control'
                    autoComplete='off'
                    value={login.email}
                    onChange={(e) => {
                      const { value } = e.target;
                      const newValue = emailMask(value);
                      fillLogin('email', newValue);
                    }}
                  />
                </Col>
              </Row>
              <Row className='mt-4 justify-content-center'>
                <Col
                  md={6}
                  className='d-flex justify-content-center'
                >
                  <button
                    className='btn-submit'
                    onClick={handleSubmit}
                  >
                    {t('home.enter')}
                  </button>
                </Col>
              </Row>
            </CardBody>
          </form>
        )}
      </Card>
    </section>
  );
};
