import './styled.css';
import { Location } from '../location';
import { useTranslation } from 'react-i18next';

export const Information = () => {
  const [t] = useTranslation();
  return (
    <>
      <Location />
      <section className='content-wrapper description'>
        <h2 className='title2'>{t('cart.description.title')}</h2>
        <p>{t('cart.description.text')}</p>
      </section>
    </>
  );
};
