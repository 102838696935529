import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TransactionContext } from '../../contexts/TransactionContext';
import { usePaymentOption } from '../../hooks/usePaymentOption';
import Header from '../../components/header';
import { PaidResponse } from '../../components/paidresponse';
import { ErrorResponse } from '../../components/errorresponse';
import { FreeResponse } from '../../components/freeresponse';
import { useTranslation } from 'react-i18next';
export const Finish = () => {
  const [t] = useTranslation();
  const { transactionResponse, paymentType, fillLogin } =
    useContext(TransactionContext);
  const { CREDITCARD, PIX, FREE } = usePaymentOption();
  const navigate = useNavigate();

  const errorMessages = {
    ALREADY_BOUGHT: t('response.error.already_bought'),
    PRODUCT_NOT_AVAILABLE: t('response.error.product_not_available'),
    PROMO_NOT_AVAILABLE: t('response.error.promot_not_available'),
  };

  const responses = {
    paid:
      (transactionResponse.success && paymentType === PIX) ||
      (transactionResponse.success && paymentType === CREDITCARD),
    free: transactionResponse.success && paymentType === FREE,
    error: transactionResponse.success === false,
  };
  return (
    <>
      <Header />
      <section className='transaction-response'>
        {responses['error'] && (
          <ErrorResponse
            message={errorMessages[transactionResponse.data.message]}
            notAvailable={
              'PRODUCT_NOT_AVAILABLE' === transactionResponse.data.message
            }
          />
        )}
        {responses['paid'] && <PaidResponse />}
        {responses['free'] && <FreeResponse />}
        <button
          className='btn-submit button-response'
          onClick={() => {
            fillLogin('language', null);
            fillLogin('country', '');
            fillLogin('email', '');
            navigate('/');
          }}
        >
          {t('home.title')}
        </button>
      </section>
    </>
  );
};
