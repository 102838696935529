import { useTranslation } from 'react-i18next';
export const ErrorResponse = ({ message, notAvailable }) => {
  const [t] = useTranslation();
  if (notAvailable) {
    return (
      <section className='message-wrapper'>
        <h3>
          {t('response.product_not_available.p1')}
          <a
            href={t('response.product_not_available.url')}
            target={'_blank'}
            rel='noreferrer'
          >
            {t('response.product_not_available.p2')}
          </a>
          {t('response.product_not_available.p3')}
        </h3>
      </section>
    );
  }
  return (
    <section className='message-wrapper'>
      <h3>{message ? message : t('response.error.title')}</h3>
    </section>
  );
};
