import { useTranslation } from 'react-i18next';

export const Location = () => {
  const [t] = useTranslation();
  return (
    <section className='content-wrapper'>
      <h1 className='title1'>{t('title')}</h1>
      <p>{t('cart.location.date')}</p>
      <p>{t('cart.location.description')}</p>

      <a
        href={t('cart.location.url')}
        target={'_blank'}
        rel='noreferrer'
      >
        {t('cart.location.see')}
      </a>
    </section>
  );
};
