import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useMask } from '../../hooks/useMask';
import { useCreditCardFlag } from '../../hooks/useCreditCardFlag';
import { useTranslation } from 'react-i18next';

export const Token = ({ cctoken, fillToken, amount, maxInstallments }) => {
  const [t] = useTranslation();
  const { numberMask, brlMask, expireAtMask, onDelete } = useMask();
  const { flags } = useCreditCardFlag();
  const [flag, setFlag] = useState('DEFAULT');

  const [amountByInstallments, setAmountByInstallments] = useState([]);

  useEffect(() => {
    const loadprices = () => {
      const prices = [];
      for (let i = 1; i <= maxInstallments; i++) {
        const newPrice = Math.round((amount / i) * 100) / 100;
        const price = {
          installments: i,
          price: newPrice,
        };
        prices.push(price);
      }
      setAmountByInstallments(prices);
    };
    loadprices();
    return () => {};
  }, [amount]);

  return (
    <>
      <h3
        className='title2'
        style={{ marginTop: 20 }}
      >
        {t('payment.creditcard.title')}
      </h3>
      <Row className='mt-2'>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-number`}
          >
            {t('payment.creditcard.number')}*
          </label>
          <div className='creditcard-number-wrapper'>
            <input
              id={`cctoken-number`}
              value={cctoken.number}
              className='form-control'
              onChange={(e) => {
                const { value } = e.target;
                let newValue = numberMask(value);

                for (let key in flags) {
                  if (flags[key].pattern.test(newValue)) {
                    setFlag(key.toString());
                    newValue = flags[key].mask(newValue);
                    fillToken('number', newValue);
                    break;
                  }
                }
              }}
              onKeyDown={(e) => {
                const newValue = onDelete(e, cctoken.number);
                fillToken('number', newValue);
              }}
              autoComplete='off'
            />
            <img
              className='creditcard-flag'
              src={flags[flag].src}
              alt='Bandeira do cartão'
            />
          </div>
        </Col>
        <Col
          md={6}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-name`}
          >
            {t('payment.creditcard.holdername')}*
          </label>
          <input
            id={`cctoken-name`}
            value={cctoken.name}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              fillToken('name', value.toUpperCase());
            }}
            autoComplete='off'
          />
        </Col>
      </Row>
      <Row className='mt-2'>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-expireAt`}
          >
            {t('payment.creditcard.expireDate')}*
          </label>
          <input
            id={`cctoken-expireAt`}
            placeholder='mm/aa'
            maxLength={5}
            value={cctoken.expireAt}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              let newValue = expireAtMask(value);
              fillToken('expireAt', newValue);
            }}
            onKeyDown={(e) => {
              const newValue = onDelete(e, cctoken.expireAt);
              fillToken('expireAt', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`cctoken-code`}
          >
            {t('payment.creditcard.cvc')}*
          </label>
          <input
            id={`cctoken-code`}
            type='password'
            maxLength={4}
            value={cctoken.code}
            className='form-control'
            onChange={(e) => {
              const { value } = e.target;
              const newValue = numberMask(value);
              fillToken('code', newValue);
            }}
            autoComplete='off'
          />
        </Col>
        <Col
          md={3}
          className='div-item-row'
        >
          <label
            className='form-label'
            style={{ paddingLeft: 0 }}
            htmlFor={`token-installments`}
          >
            {t('payment.creditcard.installments')}
          </label>
          <select
            id={`token-installments`}
            className='form-control select-style'
            disabled={false}
            value={cctoken.installments}
            onChange={(e) => {
              fillToken('installments', e.target.value);
            }}
          >
            {/* <option>{`1x de ${brlMask(amount)}`}</option> */}

            {amountByInstallments.map((price, index) => (
              <option
                key={index}
                value={price.installments}
              >
                {`${price.installments}x de ${brlMask(price.price)}`}
              </option>
            ))}
          </select>
        </Col>
      </Row>
      <Row className='mt-2'></Row>
    </>
  );
};
